import React, { useEffect, useState } from "react";
import Slider from "react-slick";
import { Box } from "@mui/material";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const CarouselComponent = () => {
  const [imagesLoaded, setImagesLoaded] = useState(false);

  const carouselImages = [
    "https://i.ibb.co/HLCVX23p/1.png",
    "https://i.ibb.co/5gW1dD9s/2.png",
    "https://i.ibb.co/PG3TLr9Q/3.png",
    "https://i.ibb.co/LDrNPRG3/4.png",
  ];

  useEffect(() => {
    let loadedCount = 0;

    carouselImages.forEach((imageSrc) => {
      const img = new Image();
      img.src = imageSrc;

      img.onload = () => {
        loadedCount++;
        if (loadedCount === carouselImages.length) {
          setImagesLoaded(true);
        }
      };

      img.onerror = () => {
        loadedCount++;
        if (loadedCount === carouselImages.length) {
          setImagesLoaded(true);
        }
      };
    });
  }, [carouselImages]);

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
  };

  if (!imagesLoaded) {
    return (
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100%",
          backgroundColor: "#f0f0f0", // Example background color for the loader
        }}
      >
        <Box
          sx={{
            width: "50px",
            height: "50px",
            border: "6px solid #ddd",
            borderTop: "6px solid orangered",
            borderRadius: "50%",
            animation: "spin 1s linear infinite",
          }}
        />
        <style>
          {`
            @keyframes spin {
              0% { transform: rotate(0deg); }
              100% { transform: rotate(360deg); }
            }
          `}
        </style>
      </Box>
    );
  }

  return (
    <Box>
      <Slider {...settings}>
        {carouselImages.map((image, index) => (
          <Box key={index}>
            <img
              src={image}
              alt={`Slide ${index + 1}`}
              style={{
                width: "100%",
                height: "auto",
                maxHeight: "500px",
                borderRadius: "16px",
              }}
              loading="lazy"
            />
          </Box>
        ))}
      </Slider>
    </Box>
  );
};

export default CarouselComponent;
