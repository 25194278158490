import React, { useState } from "react";
import {
  CardMedia,
  Dialog,
  DialogContent,
  IconButton,
  useMediaQuery,
  Box,
  Grid,
  Typography,
} from "@mui/material";
import { FaMapMarkerAlt, FaTools, FaShieldAlt } from "react-icons/fa";
import { FaGears } from "react-icons/fa6";
import PlayCircleFilledWhiteIcon from "@mui/icons-material/PlayCircleFilledWhite";
import { motion } from "framer-motion";

const BenefitsWrapper = () => {
  const isDesktop = useMediaQuery("(min-width:768px)");
  const [open, setOpen] = useState(false);

  const handlePlayButtonClick = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const videoId = "8whOjH0FO_I";
  const videoSrc = `https://www.youtube.com/embed/${videoId}?autoplay=1&rel=0`;

  const benefits = [
    {
      icon: <FaMapMarkerAlt size={30} color="orangered" />,
      title: "Free Door Step Pick-up and Drop",
      description: "Do away with the hassle of stepping out.",
    },
    {
      icon: <FaTools size={30} color="orangered" />,
      title: "Free 50 Points Inspection",
      description: "360° quality inspection with live updates.",
    },
    {
      icon: <FaGears size={30} color="orangered" />,
      title: "100% Genuine Spares",
      description: "Only company-recommended spares used. Quality guaranteed.",
    },
    {
      icon: <FaShieldAlt size={30} color="orangered" />,
      title: "35 Days Warranty",
      description: "35 Days / 1000 Km warranty on car service.",
    },
  ];

  return (
    <Box sx={{ px: { xs: 2, md: 6 }, py: 6, textAlign: "center" }}>
      {/* Header */}
      <Typography variant="h4" sx={{ fontWeight: "bold", mb: 4, color: "#222" }}>
        Select & Book a Quality Car Service
      </Typography>
      <Typography variant="h5" sx={{ fontWeight: "500", mb: 6, color: "#555" }}>
        Get Exciting Offers on Every Car Service & Repairs.
      </Typography>

      {/* Layout for Web & Mobile */}
      <Grid container spacing={4} alignItems="center">
        {/* Left Section: Benefits */}
        <Grid item xs={12} md={6}>
          <Typography variant="h5" sx={{ color: "#777", fontWeight: "600", mb: 3 }}>
            🚗 Go Clutch Benefits
          </Typography>
          {benefits.map((item, index) => (
            <motion.div
              key={index}
              whileHover={{ scale: 1.03 }}
              transition={{ duration: 0.3 }}
              style={{
                display: "flex",
                alignItems: "center",
                marginBottom: "24px",
                textAlign: "left",
              }}
            >
              {/* Icon */}
              <Box
                sx={{
                  width: 50,
                  height: 50,
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  borderRadius: "50%",
                  backgroundColor: "rgba(255, 87, 34, 0.1)",
                  marginRight: 2,
                }}
              >
                {item.icon}
              </Box>

              {/* Text */}
              <Box>
                <Typography variant="h6" sx={{ fontWeight: "600", color: "#333" }}>
                  {item.title}
                </Typography>
                <Typography variant="body2" sx={{ color: "#666" }}>
                  {item.description}
                </Typography>
              </Box>
            </motion.div>
          ))}
        </Grid>

        {/* Right Section: Video Thumbnail */}
        <Grid item xs={12} md={6} sx={{ display: "flex", justifyContent: "center" }}>
          <Box sx={{ position: "relative", width: "100%", maxWidth: "500px" }}>
            <CardMedia
              component="img"
              sx={{
                width: "100%",
                borderRadius: "16px",
                boxShadow: "0px 8px 16px rgba(0, 0, 0, 0.1)",
              }}
              image="https://i.ibb.co/pjSs8Xbm/Red-and-White-Modern-Car-Rental-Price-List-Instagram-Post-15.png"
              alt="Mechanics working"
            />
            {/* Play Button */}
            <IconButton
              onClick={handlePlayButtonClick}
              sx={{
                position: "absolute",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
                color: "white",
                fontSize: "3rem",
                bgcolor: "rgba(255, 87, 34, 0.8)",
                padding: "16px",
                borderRadius: "50%",
                transition: "0.3s",
                "&:hover": { bgcolor: "orangered" },
              }}
            >
              <PlayCircleFilledWhiteIcon sx={{ fontSize: "3.5rem" }} />
            </IconButton>
          </Box>
        </Grid>
      </Grid>

      {/* Video Dialog */}
      <Dialog open={open} onClose={handleClose} maxWidth="md" fullWidth>
        <DialogContent sx={{ padding: 0 }}>
          <iframe
            width="100%"
            height="500"
            src={videoSrc}
            allow="autoplay; encrypted-media"
            allowFullScreen
            title="Go Clutch Video"
          />
        </DialogContent>
      </Dialog>
    </Box>
  );
};

export default BenefitsWrapper;
