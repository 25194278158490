import React from 'react'
import './index.scss'

const FooterWrapper = () => {
  return (
    <>
      <div className="bottom-footer">
        <p className="bottom-footer-text">
          <span dangerouslySetInnerHTML={{ __html: "&copy;" }} /> {new Date().getFullYear()} | GO
          CLUTCH PRIVATE LIMITED - ALL RIGHTS RESERVED.
        </p>
      </div>
    </>
  );
};

export default FooterWrapper;
