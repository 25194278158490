import React, { useState } from "react";
import { Box, Button, TextField, CircularProgress, Backdrop, Typography } from "@mui/material";
import emailjs from "emailjs-com";

const FormCarousel = () => {
  const [formData, setFormData] = useState({
    name: "",
    mobile: "",
    email: "",
  });

  const [submitted, setSubmitted] = useState(false);
  const [loading, setLoading] = useState(false);
  const [showThankYouMessage, setShowThankYouMessage] = useState(false);

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const resetForm = () => {
    setFormData({
      name: "",
      mobile: "",
      email: "",
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setLoading(true); // Show loader while submitting

    const templateParams = {
      Name: formData.name,
      PhoneNumber: formData.mobile,
      Email: formData.email,
    };

    emailjs
      .send(
        "service_4wjcbsg",
        "template_shgfo2v",
        templateParams,
        "F3vMWAyM8impvHd21"
      )
      .then((response) => {
        console.log("SUCCESS!", response.status, response.text);
        setLoading(false); // Hide loader
        setSubmitted(true); // Mark form as submitted
        resetForm(); // Reset the form fields
        setShowThankYouMessage(true); // Show thank you message
        setTimeout(() => {
          setShowThankYouMessage(false); // Hide thank you message after a few seconds
          setSubmitted(false); // Reset the submitted state to show the button again
        }, 6000); // Adjust the timeout as needed
      })
      .catch((error) => {
        console.log("Failed to send email:", error);
        setLoading(false); // Hide loader on error
      });
  };

  return (
    <Box
      sx={{
        width: { xs: "100%", sm: "80%", md: "60%" },
        padding: 3,
        backgroundColor: "#fff",
        borderRadius: "16px",
        boxShadow: "0px 6px 20px rgba(0, 0, 0, 0.1)",
        textAlign: "center",
        position: "relative",
      }}
    >
      {/* Loader overlay */}
      {loading && (
        <Backdrop
          sx={{
            color: "#fff",
            zIndex: (theme) => theme.zIndex.drawer + 1,
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            backgroundColor: "rgba(0, 0, 0, 0.5)",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
          open={loading}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
      )}

      {/* Show thank you message after submission */}
      {showThankYouMessage ? (
        <Typography variant="h6" color="success.main" sx={{ marginTop: 2 }}>
          Thank you! Your form has been submitted successfully. Our representative will call you shortly!
        </Typography>
      ) : (
        <form onSubmit={handleSubmit}>
          <Box sx={{ marginBottom: 3 }}>
            <TextField
              fullWidth
              label="Enter Your Name"
              name="name"
              variant="outlined"
              value={formData.name}
              onChange={handleChange}
              required
              sx={{
                marginBottom: 2,
                backgroundColor: "#f9f9f9",
                borderRadius: "8px",
                boxShadow: "0px 3px 6px rgba(0, 0, 0, 0.05)",
              }}
            />
            <TextField
              fullWidth
              label="Enter Your Phone"
              variant="outlined"
              name="mobile"
              value={formData.mobile}
              onChange={handleChange}
              required
              sx={{
                marginBottom: 2,
                backgroundColor: "#f9f9f9",
                borderRadius: "8px",
                boxShadow: "0px 3px 6px rgba(0, 0, 0, 0.05)",
              }}
            />
            <TextField
              fullWidth
              label="Enter Your Email"
              variant="outlined"
              name="email"
              value={formData.email}
              onChange={handleChange}
              required
              sx={{
                backgroundColor: "#f9f9f9",
                borderRadius: "8px",
                boxShadow: "0px 3px 6px rgba(0, 0, 0, 0.05)",
              }}
            />
          </Box>
          {!submitted && (
            <Button
              variant="contained"
              color="error"
              type="submit"
              disabled={loading} // Disable button during loading
              sx={{
                fontWeight: "bold",
                borderRadius: "50px",
                padding: "12px 24px",
                fontSize: "18px",
                boxShadow: "0px 8px 18px rgba(211, 47, 47, 0.3)",
                background: "linear-gradient(90deg, #ff4b2b, #ffA500)",
                "&:hover": {
                  background: "linear-gradient(90deg, #ff416c, #ff4b2b)",
                },
              }}
            >
              Book Now
            </Button>
          )}
        </form>
      )}
    </Box>
  );
};

export default FormCarousel;
